import { combineReducers } from "@reduxjs/toolkit";
import fuse from "./fuse";
import i18n from "./i18nSlice";
import user from "./userSlice";
import jobs from "./jobsSlice";
import documents from "./documentsSlice";
import jobDetail from "./jobDetailSlice";
import quotes from "./quotesSlice";
import quoteActions from "./quoteActionsSlice";
import partners from "./partnersSlice";
import conversations from "./conversationsSlice";
import attachments from "./attachmentsSlice";
import proposals from "./proposalsSlice";
import organization from "./organizationSlice";
import profile from "./profileSlice";
import signup from "./signupSlice";
import workTypes from "./workTypesSlice";
import updates from "./updatesSlice";
import users from "./usersSlice";
import invoices from "./invoicesSlice";
import admin from "./adminSlice";
import contacts from "./contactsSlice";
import calendarEvents from "./calendarEventsSlice";
import serviceTerritories from "./serviceTerritoriesSlice";
import serviceTerritoryRules from "./serviceTerritoryRulesSlice";
import reports from "./reportsSlice";
import productCatalogs from "./productCatalogsSlice";
import customerPortal from "./customerPortalSlice";
import accessRules from "./accessRulesSlice";
import accounts from "./accountsSlice";
import quoteLineItems from "./quoteLineItemsSlice";
import questions from "./questionsSlice";
import jobCollaborators from "./jobCollaboratorsSlice";
import jobCustomFields from "./jobCustomFieldsSlice";
import chargebacks from "./chargebacksSlice";
import documentTypes from "./documentTypesSlice";

const createReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    fuse,
    i18n,
    user,
    jobs,
    jobDetail,
    quotes,
    partners,
    documents,
    quoteActions,
    conversations,
    attachments,
    organization,
    signup,
    users,
    profile,
    proposals,
    workTypes,
    updates,
    invoices,
    contacts,
    admin,
    serviceTerritories,
    serviceTerritoryRules,
    reports,
    calendarEvents,
    productCatalogs,
    customerPortal,
    accessRules,
    accounts,
    quoteLineItems,
    questions,
    jobCollaborators,
    chargebacks,
    jobCustomFields,
    documentTypes,
    ...asyncReducers,
  });

  /*
	Reset the redux store when user logged out
	 */
  if (action.type === "user/userLoggedOut") {
    state.user = undefined;
    state.jobs = undefined;
    state.jobDetail = undefined;
    state.quotes = undefined;
    state.partners = undefined;
    state.documents = undefined;
    state.conversations = undefined;
    state.fuse = undefined;
    state.organization = undefined;
    state.proposals = undefined;
    state.profile = undefined;
    state.workTypes = undefined;
    state.updates = undefined;
    state.contacts = undefined;
    state.invoices = undefined;
    state.reports = undefined;
    state.users = undefined;
    state.admin = undefined;
    state.accounts = undefined;
    state.serviceTerritories = undefined;
    state.serviceTerritoryRules = undefined;
    state.calendarEvents = undefined;
    state.productCatalogs = undefined;
    state.accessRules = undefined;
    state.quoteLineItems = undefined;
    state.questions = undefined;
    state.jobCollaborators = undefined;
    state.jobCustomFields = undefined;
    state.chargebacks = undefined;
    state.documentTypes = undefined;
  }

  return combinedReducer(state, action);
};

export default createReducer;
