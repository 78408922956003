import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as Constants from '../configs/constants';

import axios from 'axios';

export const createPartnerInvite = createAsyncThunk(
  'partner/invite',
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios.post(Constants.API_URL + 'Accounts/createPartnerAccount', userData, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
        }
      })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const addPartner = createAsyncThunk(
  'partner/add',
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios.post(Constants.API_URL + 'Accounts/addPartner', userData, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
        }
      })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getPartnerInvites = createAsyncThunk(
  'invites/list',
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios.post(Constants.API_URL + 'Partners/getPartners', userData, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
        }
      })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      if (e && e.response) {
        console.log('Error', e.response.data);
        return thunkAPI.rejectWithValue(e.response.data);
      }
      else {
        console.log('Error', e.message);
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  }
);

export const getPartnerInvitesByJob = createAsyncThunk(
  'partners/list',
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios.post(Constants.API_URL + 'Partners/getPartnersByJobId', userData, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
        }
      })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      if (e && e.response) {
        console.log('Error', e.response.data);
        return thunkAPI.rejectWithValue(e.response.data);
      }
      else {
        console.log('Error', e.message);
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  }
);

export const getPartnersBySearch = createAsyncThunk(
  'partnersSearch/list',
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios.post(Constants.API_URL + 'Partners/getPartnersBySearch', userData, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
        }
      })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      if (e && e.response) {
        console.log('Error', e.response.data);
        return thunkAPI.rejectWithValue(e.response.data);
      }
      else {
        console.log('Error', e.message);
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  }
);

export const getPartnerInvitesByJobST = createAsyncThunk(
  'partners/serviceterritory',
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios.post(Constants.API_URL + 'Partners/getPartnersByJobIdST', userData, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
        }
      })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      if (e && e.response) {
        console.log('Error', e.response.data);
        return thunkAPI.rejectWithValue(e.response.data);
      }
      else {
        console.log('Error', e.message);
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  }
);

export const requestAQuote = createAsyncThunk(
  'request/quote',
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios.post(Constants.API_URL + 'Quotes/requestAQuote', userData, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
        }
      })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const deletePartnerInvite = createAsyncThunk(
  'partner/deleteInvite',
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios.post(Constants.API_URL + 'Partners/deleteInvite', userData, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
        }
      })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const resendPartnerInvite = createAsyncThunk(
  'invites/resend',
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios.post(Constants.API_URL + 'Partners/resendPartnerInvite', userData, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
        }
      })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      if (e && e.response) {
        console.log('Error', e.response.data);
        return thunkAPI.rejectWithValue(e.response.data);
      }
      else {
        console.log('Error', e.message);
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  }
);

export const updatePartnerBackgroundStatus = createAsyncThunk(
  "partner/updateStatus",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Partners/updatePartnerBackgroundStatus", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updatePartnerComplianceStatus = createAsyncThunk(
  "partner/updatePartnerComplianceStatus",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Partners/updatePartnerComplianceStatus", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const extractDocumentPDFData = createAsyncThunk(
  "partner/document/extractPDFData",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Documents/extractDocumentPDF", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const partnersSlice = createSlice({
  name: 'partners',
  initialState: {
    isPartnersListFetching: false,
    isPartnersListSuccess: false,
    isPartnersListError: false,
    partnersListErrorMessage: '',
    partnersList: [],
    isPartnersListBySearchFetching: false,
    isPartnersListBySearchSuccess: false,
    isPartnersListBySearchError: false,
    partnersListBySearchErrorMessage: '',
    partnersListBySearch: [],
    isJobPartnersListFetching: false,
    isJobPartnersListSuccess: false,
    isJobPartnersListError: false,
    jobPartnersListErrorMessage: '',
    jobPartnersList: [],
    isJobSTPartnersListFetching: false,
    isJobSTPartnersListSuccess: false,
    isJobSTPartnersListError: false,
    jobSTPartnersListErrorMessage: '',
    jobSTPartnersList: [],
    isInvitePartnerFetching: false,
    isInvitePartnerSuccess: false,
    isInvitePartnerError: false,
    invitePartnerErrorMessage: '',
    isAddPartnerFetching: false,
    isAddPartnerSuccess: false,
    isAddPartnerError: false,
    addPartnerErrorMessage: '',
    isRequestAQuoteFetching: false,
    isRequestAQuoteSuccess: false,
    isRequestAQuoteError: false,
    requestAQuoteErrorMessage: '',
    isDeletePartnerInviteFetching: false,
    isDeletePartnerInviteSuccess: false,
    isDeletePartnerInviteError: false,
    deletePartnerInviteErrorMessage: '',

    isResendPartnerInviteFetching: false,
    isResendPartnerInviteSuccess: false,
    isResendPartnerInviteError: false,
    resendPartnerInviteErrorMessage: '',

    isUpdateBackgroundStatusFetching: false,
    isUpdateBackgroundStatusSuccess: false,
    isUpdateBackgroundStatusError: false,
    updateBackgroundStatusErrorMessage: "",

    isUpdateComplianceStatusFetching: false,
    isUpdateComplianceStatusSuccess: false,
    isUpdateComplianceStatusError: false,
    updateComplianceStatusErrorMessage: "",

    isExtractDocumentDataFetching: false,
    isExtractDocumentDataSuccess: false,
    isExtractDocumentDataError: false,
    extractDocumentDataErrorMessage: "",
  },
  reducers: {
    clearInvitePartnerState: (state) => {
      state.isInvitePartnerFetching = false;
      state.isInvitePartnerSuccess = false;
      state.isInvitePartnerError = false;
      state.invitePartnerErrorMessage = "";
      return state;
    },
    clearAddPartnerState: (state) => {
      state.isAddPartnerFetching = false;
      state.isAddPartnerSuccess = false;
      state.isAddPartnerError = false;
      state.addPartnerErrorMessage = "";
      return state;
    },
    clearDeletePartnerInviteState: (state) => {
      state.isDeletePartnerInviteFetching = false;
      state.isDeletePartnerInviteSuccess = false;
      state.isDeletePartnerInviteError = false;
      state.deletePartnerInviteErrorMessage = "";
      return state;
    },
    clearResendPartnerInviteState: (state) => {
      state.isResendPartnerInviteFetching = false;
      state.isResendPartnerInviteSuccess = false;
      state.isResendPartnerInviteError = false;
      state.resendPartnerInviteErrorMessage = "";
      return state;
    },
    clearPartnersListState: (state) => {
      state.isPartnersListFetching = false;
      state.isPartnersListSuccess = false;
      state.isPartnersListError = false;
      state.partnersList = [];
      return state;
    },
    clearPartnersListBySearchState: (state) => {
      state.isPartnersListBySearchFetching = false;
      state.isPartnersListBySearchSuccess = false;
      state.isPartnersListBySearchError = false;
      state.partnersListBySearch = [];
      return state;
    },
    clearJobPartnersListState: (state) => {
      state.isJobPartnersListFetching = false;
      state.isJobPartnersListSuccess = false;
      state.isJobPartnersListError = false;
      state.jobPartnersList = [];
      return state;
    },
    clearJobSTPartnersListState: (state) => {
      state.isJobSTPartnersListFetching = false;
      state.isJobSTPartnersListSuccess = false;
      state.isJobSTPartnersListError = false;
      state.jobSTPartnersList = [];
      return state;
    },
    clearRequestAQuoteState: (state) => {
      state.isRequestAQuoteFetching = false;
      state.isRequestAQuoteSuccess = false;
      state.isRequestAQuoteError = false;
      return state;
    },
    clearPartnerUpdateBackgroundStatusState: (state) => {
      state.isUpdateBackgroundStatusError = false;
      state.isUpdateBackgroundStatusSuccess = false;
      state.isUpdateBackgroundStatusFetching = false;
      state.updateBackgroundStatusErrorMessage = "";
      return state;
    },
    clearPartnerUpdateComplianceStatusState: (state) => {
      state.isUpdateComplianceStatusError = false;
      state.isUpdateComplianceStatusSuccess = false;
      state.isUpdateComplianceStatusFetching = false;
      state.updateComplianceStatusErrorMessage = "";
      return state;
    },
    clearExtractDocumentDataState: (state) => {
      state.isExtractDocumentDataError = false;
      state.isExtractDocumentDataSuccess = false;
      state.isExtractDocumentDataFetching = false;
      state.extractDocumentDataErrorMessage = "";
      return state;
    },
  },
  extraReducers: {
    [deletePartnerInvite.fulfilled]: (state, { payload }) => {
      state.isDeletePartnerInviteFetching = false;
      state.isDeletePartnerInviteSuccess = true;
      return state;
    },
    [deletePartnerInvite.rejected]: (state, { payload }) => {
      state.isDeletePartnerInviteFetching = false;
      state.isDeletePartnerInviteError = true;
      state.deletePartnerInviteErrorMessage = payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [deletePartnerInvite.pending]: (state) => {
      state.isDeletePartnerInviteFetching = true;
    },
    [resendPartnerInvite.fulfilled]: (state, { payload }) => {
      state.isResendPartnerInviteFetching = false;
      state.isResendPartnerInviteSuccess = true;
      return state;
    },
    [resendPartnerInvite.rejected]: (state, { payload }) => {
      state.isResendPartnerInviteFetching = false;
      state.isResendPartnerInviteError = true;
      state.resendPartnerInviteErrorMessage = payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [resendPartnerInvite.pending]: (state) => {
      state.isResendPartnerInviteFetching = true;
    },
    [addPartner.fulfilled]: (state, { payload }) => {
      state.isAddPartnerFetching = false;
      state.isAddPartnerSuccess = true;
      return state;
    },
    [addPartner.rejected]: (state, { payload }) => {
      state.isAddPartnerFetching = false;
      state.isAddPartnerError = true;
      state.addPartnerErrorMessage = payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [addPartner.pending]: (state) => {
      state.isAddPartnerFetching = true;
    },
    [createPartnerInvite.fulfilled]: (state, { payload }) => {
      state.isInvitePartnerFetching = false;
      state.isInvitePartnerSuccess = true;
      return state;
    },
    [createPartnerInvite.rejected]: (state, { payload }) => {
      state.isInvitePartnerFetching = false;
      state.isInvitePartnerError = true;
      state.invitePartnerErrorMessage = payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [createPartnerInvite.pending]: (state) => {
      state.isInvitePartnerFetching = true;
    },
    [getPartnerInvites.fulfilled]: (state, { payload }) => {
      state.isPartnersListFetching = false;
      state.partnersList = payload;
      state.isPartnersListSuccess = true;
      return state;
    },
    [getPartnerInvites.rejected]: (state, { payload }) => {
      state.isPartnersListFetching = false;
      state.isPartnersListError = true;
      state.partnersListErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [getPartnerInvites.pending]: (state) => {
      state.isPartnersListFetching = true;
    },
    [getPartnerInvitesByJob.fulfilled]: (state, { payload }) => {
      state.isJobPartnersListFetching = false;
      state.jobPartnersList = payload || [];
      state.isJobPartnersListSuccess = true;
      return state;
    },
    [getPartnerInvitesByJob.rejected]: (state, { payload }) => {
      state.isJobPartnersListFetching = false;
      state.isJobPartnersListError = true;
      state.jobPartnersListErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [getPartnerInvitesByJob.pending]: (state) => {
      state.isJobPartnersListFetching = true;
    },
    [getPartnerInvitesByJobST.fulfilled]: (state, { payload }) => {
      state.isJobSTPartnersListFetching = false;
      state.jobSTPartnersList = payload;
      state.isJobSTPartnersListSuccess = true;
      return state;
    },
    [getPartnerInvitesByJobST.rejected]: (state, { payload }) => {
      state.isJobSTPartnersListFetching = false;
      state.isJobSTPartnersListError = true;
      state.jobSTPartnersListErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [getPartnerInvitesByJobST.pending]: (state) => {
      state.isJobSTPartnersListFetching = true;
    },
    [getPartnersBySearch.fulfilled]: (state, { payload }) => {
      state.isPartnersListBySearchFetching = false;
      state.partnersListBySearch = payload;
      state.isPartnersListBySearchSuccess = true;
      return state;
    },
    [getPartnersBySearch.rejected]: (state, { payload }) => {
      state.isPartnersListBySearchFetching = false;
      state.isPartnersListBySearchError = true;
      state.partnersListBySearchErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [getPartnersBySearch.pending]: (state) => {
      state.isPartnersListBySearchFetching = true;
    },
    [requestAQuote.fulfilled]: (state, { payload }) => {
      state.isRequestAQuoteFetching = false;
      state.isRequestAQuoteSuccess = true;
      return state;
    },
    [requestAQuote.rejected]: (state, { payload }) => {
      state.isRequestAQuoteFetching = false;
      state.isRequestAQuoteError = true;
      state.requestAQuoteErrorMessage = payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [requestAQuote.pending]: (state) => {
      state.isRequestAQuoteFetching = true;
    },
    [updatePartnerBackgroundStatus.fulfilled]: (state, { payload }) => {
      state.isUpdateBackgroundStatusFetching = false;
      state.isUpdateBackgroundStatusSuccess = true;
      return state;
    },
    [updatePartnerBackgroundStatus.rejected]: (state, { payload }) => {
      state.isUpdateBackgroundStatusFetching = false;
      state.isUpdateBackgroundStatusError = true;
      state.updateBackgroundStatusErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [updatePartnerBackgroundStatus.pending]: (state) => {
      state.isUpdateBackgroundStatusFetching = true;
    },
    [updatePartnerComplianceStatus.fulfilled]: (state, { payload }) => {
      state.isUpdateComplianceStatusFetching = false;
      state.isUpdateComplianceStatusSuccess = true;
      return state;
    },
    [updatePartnerComplianceStatus.rejected]: (state, { payload }) => {
      state.isUpdateComplianceStatusFetching = false;
      state.isUpdateComplianceStatusError = true;
      state.updateComplianceStatusErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [updatePartnerComplianceStatus.pending]: (state) => {
      state.isUpdateComplianceStatusFetching = true;
    },
    [extractDocumentPDFData.fulfilled]: (state, { payload }) => {
      state.isExtractDocumentDataFetching = false;
      state.isExtractDocumentDataSuccess = true;
      return state;
    },
    [extractDocumentPDFData.rejected]: (state, { payload }) => {
      state.isExtractDocumentDataFetching = false;
      state.isExtractDocumentDataError = true;
      state.extractDocumentDataErrorMessage =
        payload && payload.error_description
          ? payload.error_description
          : "Invalid Credentials. Please try again!";
    },
    [extractDocumentPDFData.pending]: (state) => {
      state.isExtractDocumentDataFetching = true;
    },
  },
});

export const { clearInvitePartnerState,clearExtractDocumentDataState ,clearResendPartnerInviteState, clearAddPartnerState, clearPartnersListBySearchState, clearJobSTPartnersListState, clearPartnersListState, clearRequestAQuoteState, clearJobPartnersListState, clearDeletePartnerInviteState, clearPartnerUpdateBackgroundStatusState, clearPartnerUpdateComplianceStatusState } = partnersSlice.actions;

export const partnersSelector = (state) => state.partners;
export const partnersListSelector = (state) => state.partners && state.partners.partnersList && state.partners.partnersList.partners_list || [];
export const partnersInviteListSelector = (state) => state.partners && state.partners.partnersList && state.partners.partnersList.invite_list || [];
export const partnersInviteSelector = (state) => state.partners.jobPartnersList;
export default partnersSlice.reducer;